import css from './css/popularity.module.css';

function Popularity() {
    return (
        <div className={css.block_popularity}>
            Популярно!
        </div>
    )
}

export default Popularity;
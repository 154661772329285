import Footer from "../../Components/Footer/Footer";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import Header from "../../Components/Header/Header";
import { useEffect, useState } from "react";
import { getPhilosophyPost } from "../../utils/axios";
import { Link, useParams } from "react-router-dom";
import css from "../css/post.module.css";

function PhilosophyPost() {
  const params = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    getPhilosophyPost(params.id)
      .then(data => setPost(data));
  }, []);

  useEffect(() => {
    document.title = post?.title + ' | Психология | Кратко. Чётко. Понятно.';
  }, [post?.title]);

  if (!post) {
    return (
      <div>
        <Header />

        <div className={css.post}>
          <h1 className={css.post__error}>Пост не найден :(</h1>

          <Link className={css.post__link} to={"/"}>
            Продолжить саморазвиваться
          </Link>
        </div>
      </div>
    );
  }

  let text = [];

  for (let i in post.text.split("\n\r")) {
    text.push(
      <p data-aos="fade-right" className={css.post__text}>
        {post.text.split("\n\r")[i]}
      </p>
    );
  }

  return (
    <div>
      <Header />

      <BreadCrumbs name="Философия" link="/philosophy" />

      <div className={css.post}>
        <img
          data-aos="fade-right"
          src={`http://5.35.99.37:5050/${post?.imgUrl}`}
          className={css.post__image}
          alt=""
        />

        <h1 data-aos="fade-right" className={css.post__title}>
          {post?.title}
        </h1>

        { text }
      </div>

      <Footer />
    </div>
  );
}

export default PhilosophyPost;

import Header from '../../Components/Header/Header';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import New from '../../Components/New/New';
import Popularity from '../../Components/Popularity/Popularity';

import { useEffect, useState } from 'react';
import { getPsychologyPosts } from '../../utils/axios';
import css from '../css/sections.module.css';
import searchLogo from './../../i/search_image.png';

import { Link } from 'react-router-dom';
import { searchFunction } from '../../utils/fuse';

function Psychology() {
  const [posts, setPosts] = useState('');
  const [search, setSearch] = useState('');

  useEffect(() => {
    getPsychologyPosts().then((data) => setPosts(data));
  }, []);

  useEffect(() => {
    document.title = 'Психология | Кратко. Чётко. Понятно.';
  }, []);

  if (!posts.posts) {
    return (
      <div className={css.bug}>
        <Header />

        <div className={css.spinner}>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <h1 className={css.bug__title}>Возникли технические неполадки</h1>

        <Link to={'https://t.me/xae6ka'} className={css.bug__subtitle}>
          Техническая поддержка
        </Link>
      </div>
    );
  }

  return (
    <div>
      <Header />

      <BreadCrumbs name="Психология" link="/psychology" />

      <div className={css.search} data-aos="fade-down">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={css.search__input}
          placeholder="Поиск..."
        />

        <img
          src={searchLogo}
          alt=""
          width={'30px'}
          height={'30px'}
          className={css.search__logo}
        />
      </div>

      {!search && posts.posts.length ? (
        <>
          <Link to={posts.posts[0]?._id} className={css.post}>
            <h4 className={css.link}>{posts.posts[0]?.title}</h4>

            <New />
          </Link>

          <hr />
        </>
      ) : null}

      {searchFunction(posts.posts, search).map((post) => (
        <Link key={post._id} to={post._id} className={css.post}>
          <h4 className={css.link}>{post.title}</h4>

          {post.popularity ? <Popularity /> : null}
        </Link>
      ))}
    </div>
  );
}

export default Psychology;

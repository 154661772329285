import './css/footerM.css';

const FooterM = () => {
  return (
    <footer className='footerM'>
        <h1 className='footerM__text'>
            By <a className='footerM__link' href="https://t.me/xae6ka_room">Мамкин Программист</a>
        </h1>
    </footer>
  )
}

export default FooterM;
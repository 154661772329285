import Header from '../../Components/Header/Header';
import Main from '../../Components/Main/Main';
import Nav from '../../Components/Nav/Nav';
import LastPosts from '../../Components/LastPosts/LastPosts';
// import AuthorWords from '../../Components/AuthorWords/AuthorWords.jsx';
import FooterM from '../../Components/FooterM/FooterM';

function FirstPage() {
    return (
        <div>
            <Header />

            <Main />

            <hr />

            <LastPosts />

            <hr />

            <Nav />

            {/* <AuthorWords /> */}
            
            <FooterM />
        </div>
    )
}

export default FirstPage;
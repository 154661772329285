import css from './css/new.module.css';

function New() {
    return (
        <div className={css.block_new}>
            New!
        </div>
    )
}

export default New;
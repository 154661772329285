function Admin() {
  return (
    <section class="container">
      <ul class="nav justify-content-center" style={{ marginBottom: '50px' }}>
        <li class="nav-item">
          <a
            class="nav-link active"
            aria-current="page"
            href="/createselfdevelopmentpost"
          >
            Саморазвитие
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/CreatePsychologyPost">
            Психология
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/createphilosophypost">
            Философия
          </a>
        </li>
      </ul>

      <div class="alert alert-warning" role="alert">
        Внимание! Эта страница предназначена для администраторов проекта
      </div>

      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              v0.4.3 (In Development + Now)
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Изменил поиск
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              v0.4.2 (Old)
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Добавил /admin
              <br />
              Изменил состояние загрузки <code>LastPosts</code> компонент
              <br />
              Удалил Redux, переписал на чистый axios requests
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              v0.4.1
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Добавил <code>LastPosts</code> на главную
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Admin;

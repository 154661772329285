import React from "react";
import arrow_right from './../../i/arrow-right.png';
import css from './css/breadcrumbs.module.css';

function BreadCrumbs(props) {
  return (
    <span className={css.spanL}>
      <a href="/" className={css.spanL__link}>Главная</a> <img src={arrow_right} alt="" className={css.arrow_right} /> <a href={props.link} className={css.spanL__link}>{props.name}</a>
    </span>
);
}

export default BreadCrumbs;

import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://5.35.99.37:5050/api',
});

axios.defaults.baseURL = 'http://5.35.99.37:5050/api/';
axios.defaults.headers.post = {
  'Content-Type': 'multipart/form-data',
};

// ====================== GetLastPosts ============================

export const getLastsPosts = async () => {
  try {
    const { data } = await axios.get('getlastposts');

    return data;
  } catch (err) {
    console.log(err);
  }
};

// ====================== Self Development Posts ============================

export const getSelfDevelopmentPosts = async () => {
  try {
    const { data } = await axios.get('selfdevelopment/getposts');

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getSelfDevelopmentPost = async (id) => {
  try {
    const { data } = await axios.get(`selfdevelopment/${id}`);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const addSelfDevelopmentPost = async (formdata) => {
  try {
    const post = {};

    for (let [i, j] of formdata) {
      post[i] = j;
    }

    const { data } = await axios.post('selfdevelopment/addpost', { ...post });

    return data;
  } catch (err) {
    console.log(err);
  }
};

// ====================== Psychology Posts ============================

export const getPsychologyPosts = async () => {
  try {
    const { data } = await axios.get('psychology/getposts');

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getPsychologyPost = async (id) => {
  try {
    const { data } = await axios.get(`psychology/${id}`);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const addPsychologyPost = async (formdata) => {
  try {
    const post = {};

    for (let [i, j] of formdata) {
      post[i] = j;
    }

    const { data } = await axios.post('psychology/addpost', { ...post });

    return data;
  } catch (err) {
    console.log(err);
  }
};

// ====================== Philosophy Posts ============================

export const getPhilosophyPosts = async () => {
  try {
    const { data } = await axios.get('philosophy/getposts');

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getPhilosophyPost = async (id) => {
  try {
    const { data } = await axios.get(`philosophy/${id}`);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const addPhilosophyPost = async (formdata) => {
  try {
    const post = {};

    for (let [i, j] of formdata) {
      post[i] = j;
    }

    const { data } = await axios.post('philosophy/addpost', { ...post });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export default instance;

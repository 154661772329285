import { useState } from 'react';
import { addSelfDevelopmentPost } from '../../utils/axios';

function App() {
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [popularity, setPopularity] = useState('');
  const [password, setPassword] = useState('');

  let data = new FormData();

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      data.append('title', title);
      data.append('text', text);
      data.append('image', image);
      data.append('popularity', popularity);
      data.append('password', password);

      addSelfDevelopmentPost(data);
      setTitle('');
      setText('');
      setPopularity('');
      setPassword('');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form>
      <div class="mb-3">
        <label for="title" class="form-label">
          title
        </label>
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          class="form-control"
          id="title"
          aria-describedby="emailHelp"
        />
      </div>
      <div class="mb-3">
        <label for="text" class="form-label">
          Text
        </label>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          class="form-control"
          id="text"
          rows="3"
        ></textarea>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">
          Password
        </label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          class="form-control"
          id="password"
        />

        <select
          style={{ marginTop: '35px' }}
          class="form-select"
          aria-label="Default select example"
          value={popularity}
          onChange={(e) => setPopularity(e.target.value)}
        >
          <option selected>Add option</option>
          <option value="popularity">popularity</option>
        </select>
      </div>
      <div class="input-group" style={{ marginBottom: '10px' }}>
        <input
          placeholder="file"
          onChange={(e) => setImage(e.target.files[0])}
          type="file"
          class="form-control"
          id="file"
          aria-describedby="inputGroupFileAddon04"
          aria-label="Upload"
        />
      </div>
      <button onClick={handleSubmit} type="submit" class="btn btn-primary">
        Add Self Development Post
      </button>
    </form>
  );
}

export default App;

import Fuse from 'fuse.js';

const fuseOptions = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: false,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.6,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: false,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ['title'],
};

export const searchFunction = (posts, searchStr) => {
  const fuse = new Fuse(posts, fuseOptions);

  if (searchStr) {
    const array = [];
    const res = fuse.search(searchStr);

    for (let i of res) {
      array.push(i.item);
    }

    return array;
  } else {
    const array = [];

    for (let i in posts) {
      // eslint-disable-next-line
      if (i != 0) {
        array.push(posts[i]);
      }
    }
    
    return array;
  }
};

import { getLastsPosts } from '../../utils/axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import css from './css/lastposts.module.css';

function LastPosts() {
  const [data, setData] = useState();

  useEffect(() => {
    getLastsPosts().then((res) => setData(res));
  }, []);

  if (data?.data) {
    return (
      <section className={css.lastposts}>
        <h1 className={css.lastposts__title} data-aos="zoom-in-up">
          Самое Новое
        </h1>

        <Link
          to={'/self-development/' + data.data[0]._id}
          className={css.lastposts__post}
        >
          <span className={css.post__title} data-aos="fade-up-right">
            {data.data[0].title}
          </span>

          <span className={css.post__chapter} data-aos="fade-up-left">
            Саморазвитие
          </span>
        </Link>

        <Link
          to={'/psychology/' + data.data[1]._id}
          className={css.lastposts__post}
        >
          <span className={css.post__title} data-aos="fade-up-right">
            {data.data[1].title}
          </span>

          <span className={css.post__chapter} data-aos="fade-up-left">
            Психология
          </span>
        </Link>

        <Link
          to={'/philosophy/' + data.data[2]._id}
          className={css.lastposts__post}
        >
          <span className={css.post__title} data-aos="fade-up-right">
            {data.data[2].title}
          </span>

          <span className={css.post__chapter} data-aos="fade-up-left">
            Философия
          </span>
        </Link>
      </section>
    );
  } else {
    return (
      <div className={css.lastposts}>
        <h1 className={css.lastposts__title} data-aos="zoom-in-up">
          Последние посты подгружаются
        </h1>

        <div class="spinner-border" className={css.lastposts__spinner} role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
}

export default LastPosts;

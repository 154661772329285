import css from './css/main.module.css';
import main_logo from './../../i/main_logo.svg';

function App() {
    return (
        <main>
            <p className={css.main__text} data-aos="fade-right">
                Сайт для изучения психологии, философии и многих других тем кратко и без воды. Залетай!
            </p>

            <img className={css.main__img} src={main_logo} alt="" data-aos="fade-left" />
        </main>
    )
}

export default App;